import './Quizmaster.css';
import React, { useEffect, useState, useRef } from 'react';
import { TalkingHead } from "../utils/Talkinghead";
import axios from 'axios';


let head = null;
let loaded=false;
let isAvatarThinking=false; // Between ending the recording and the text being spoken

let showPopup = true; // Consent for video and microphone


function Quizmaster() {
  const videoRef = useRef();
  const [pauseButtonText, setPauseButtonText] = useState('Pause');
  const [consentVideoMicrophone, setConsentVideoMicrophone] = useState(false);
  const [lockStartButton, setLockStartButton] = useState(true);

  const [subject, setSubject] = useState('');
  const [questions, setQuestions] = useState([]);
  const [questionAmount, setQuestionAmount] = useState(1);
  const [questionCounter, setQuestionCounter] = useState(0);
  const [isQuizActive, setIsQuizActive] = useState(false);
  const [showPopup, setShowPopup] = useState(true);
  const [showQuestion, setShowQuestion] = useState(false);

  const [selectedAnswer, setSelectedAnswer] = useState(null); // Track selected answer
  const [correctAnswerIndex, setCorrectAnswerIndex] = useState(null); // Track correct answer index
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(null); // Track if answer is correct

  useEffect(() => {
    if (!loaded && !isQuizActive) {
      loadAvatar();
      setTimeout(() => {
        setLockStartButton(false);
      }, 3000);
    } else if (isQuizActive) {
      setQuestionAmount(questions.length);
      StartQuestion();
    }
  }, [isQuizActive]);

  
  // Loads the avatar on the screen.
  const loadAvatar=async function loadAvatar() {
    if (loaded) { 
      return;
    }
    loaded=true;

      // Instantiate the class
      // NOTE: Never put your API key in a client-side code unless you know
      //       that you are the only one to have access to that code!
      const nodeAvatar = document.getElementById('avatar');
      head = new TalkingHead(nodeAvatar, {
        ttsEndpoint: "https://eu-texttospeech.googleapis.com/v1beta1/text:synthesize",
        ttsApikey: process.env.REACT_APP_GOOGLE_TTS_API_KEY, 
        lipsyncModules: ["en"],
        cameraView: "upper"
      });

      // Load and show the avatar
      const nodeLoading = document.getElementById('loading');
      try {
        nodeLoading.textContent = "Loading...";
        await head.showAvatar({
          // Url can be changed for other models
          //url: 'https://models.readyplayer.me/669a5e4c7b8266b463ef1261.glb?morphTargets=ARKit,Oculus+Visemes,mouthOpen,mouthSmile,eyesClosed,eyesLookUp,eyesLookDown&textureSizeLimit=1024&textureFormat=png',
          // body: 'F',
          // ttsVoice: "en-GB-Standard-A",
          url: 'https://models.readyplayer.me/66defc1e253e9210e373b22f.glb?morphTargets=ARKit,Oculus+Visemes,mouthOpen,mouthSmile,eyesClosed,eyesLookUp,eyesLookDown&textureSizeLimit=1024&textureFormat=png',
          body: 'M',
          ttsVoice: "en-GB-Standard-B",
          avatarMood: 'neutral',
          ttsLang: "en-GB",
          
          lipsyncLang: 'en'
        }, (ev) => {
          if (ev.lengthComputable) {
            let val = Math.min(1000, Math.round(ev.loaded / ev.total * 100));
            nodeLoading.textContent = "Loading " + val + "%";
          }
   
        });
        nodeLoading.style.display = 'none';
      } catch (error) {
        nodeLoading.textContent = error.toString();
      }     
  }

function pauseButton(){
  if (isAvatarThinking){
      head.start();
      setPauseButtonText('Pause');
  } else {
      head.stop();
      setPauseButtonText('Resume');
  }
  isAvatarThinking = !isAvatarThinking;
}

  // Close the introduction modal
  function closeModal(){
    setShowPopup(false);
    head.speakText(`Let me tell you how this quiz will work while it loads. I will ask you a series of questions. You will have to answer them by clicking on the correct answer. Are you ready? We will begin soon.`);
    GenerateQuiz();
  }

  async function GenerateQuiz(){
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/VHQuiz`, {subject: subject, amount: questionAmount});
    setQuestions(response.data.questions);
    setQuestionCounter(0);
    setIsQuizActive(true);
  }

  const AnswerClick = (item, index) => {
    if (item === questions[questionCounter].answerCorrect){
      head.speakText(`Correct! ${questions[questionCounter].answerCorrectDescription}`);
      setIsAnswerCorrect(true);
    } else {
      head.speakText(`Incorrect! ${questions[questionCounter].answerCorrectDescription}`);
      setIsAnswerCorrect(false);
    }

    // Update selected answer and correct answer index
    setSelectedAnswer(index);
    setCorrectAnswerIndex(questions[questionCounter].answerOptions.indexOf(questions[questionCounter].answerCorrect));

    console.log(`Question counter: ${questionCounter}`);
    if (questionCounter < questionAmount -1){
      StartNextQuestion();
    } else {
      EndQuiz();
    }
  };

  function ShowPopup(){
    if (!head.isSpeaking){
      setShowPopup(true);
    } else {
      setTimeout(() => {
        ShowPopup();
      }, 1000);
    }

  }

  function StartQuestion(){
    if (!head.isSpeaking){
      setShowQuestion(true);
      head.speakText(`${questions[questionCounter].question}`);
      
    } else {
      setTimeout(() => {
        StartQuestion();
      }, 1000);
      
    }
  }


  function StartNextQuestion(){
    if (!head.isSpeaking){
      setSelectedAnswer(null);
      setIsAnswerCorrect(null);
      setShowQuestion(true);
      setQuestionCounter(questionCounter + 1);
      head.speakText(`${questions[questionCounter + 1].question}`);
      
    } else {
      setTimeout(() => {
        StartNextQuestion();
      }, 1000);
      
    }
  }

  function EndQuiz(){
    if (!head.isSpeaking){
      head.speakText(`That was the last question. Thank you for playing!`);
      
      setTimeout(() => {
        setSelectedAnswer(null);
        setIsAnswerCorrect(null);
        setCorrectAnswerIndex(null);
        setQuestions([]);
        setQuestionAmount(1);
        setQuestionCounter(0);
        setShowQuestion(false);
        setIsQuizActive(false);
        ShowPopup();
      }, 5000);
    } else {
      setTimeout(() => {
        EndQuiz();
      }, 1000);
      
    }
  }

  const incrementValue = () => {
    setQuestionAmount((prev) => (prev < 10 ? prev + 1 : 10));
  };

  const decrementValue = () => {
    setQuestionAmount((prev) => (prev > 1 ? prev - 1 : 1));
  };

  return (
    <div>
      <div className="App">
      <div className="avatar-background">
        <div className='avatar QuizMaster-avatar-position' id="avatar"></div>
        <div id="loading"></div>
      </div>
      {/* {!showPopup && <button className="pause-button button" onClick={pauseButton}>{pauseButtonText}</button>} */}
      {!showPopup && showQuestion && (
        <div>
          <div className='container'>
            <label className="question">{questions[questionCounter].question}</label>
          </div>
          <div className="button-grid-wrapper">
            <div className="button-grid">
                {questions[questionCounter].answerOptions.map((option, index) => (
                  <button
                    key={index}
                    onClick={() => AnswerClick(option, index)}
                    className={
                      selectedAnswer !== null
                        ? index === correctAnswerIndex
                          ? "correct"
                          : index === selectedAnswer
                          ? isAnswerCorrect
                            ? "correct"
                            : "incorrect"
                          : ""
                        : ""
                    }
                    disabled={selectedAnswer !== null} // Disable buttons after an answer is selected
                  >
                    {option}
                  </button>
                ))}
              </div>
            </div>
        </div>
      )}
      {showPopup &&
        <div className="intro-modal">
          <div className="intro-modal__text">
            <div>
              <h3>Hi, I'm <span style={{color: '#F36F43'}}>The Quizmaster</span></h3> 
              <p className="main-text">What would you like to be quizzed on today?</p>
              <input type="text" placeholder="Enter a subject" className="QuizMaster-input-field" onChange={(e) => setSubject(e.target.value)}/>
              <p className="main-text">How many questions would you like to answer?</p>
              <div className="QuizMaster-input-wrapper">
                <input type="number" placeholder="Enter the number of questions" className="QuizMaster-input-field" value={questionAmount} onChange={(e) => setQuestionAmount(e.target.value)} min="0" max="10" step="1"/>
                <div className="arrows">
                  <button type="button" className="arrow up-arrow" onClick={incrementValue}>▲</button>
                  <button type="button" className="arrow down-arrow" onClick={decrementValue}>▼</button>
                </div>
              </div>
              {/* <p className="final-step">Ask <span style={{color: '#F36F43'}}>Max</span> who he is and what his role is at United Playgrounds</p> */}
              <p className="final-step">Turn on your sound, and whenever you are ready, click <span style={{color: '#F36F43'}}>start!</span></p>
            </div>
            <button className="button" onClick={closeModal}>Start</button>
          </div>
          <div className="intro-modal__image">
            <img src="images/mach8-tile.png"/>
          </div>
        </div>
      }
      </div>
    </div>


    
  );
}

export default Quizmaster;
