import "./KinderStories.css";
import React, { useEffect, useState, useRef } from "react";
import { TalkingHead } from "../utils/Talkinghead";
import axios from "axios";
import { base64ToArrayBuffer } from "../utils/base64toArraybuffer";
import {
  KinderStoriesGreetingEN,
  KinderStoriesEndingEN,
  KinderStoriesGreetingNL,
  KinderStoriesEndingNL,
} from "./KinderStoriesStandardSpeech";

let head = null;
let loaded = false;
let isWaitingForDescriptionResult = false;
let isAvatarThinking = false; // Between ending the recording and the text being spoken

function App() {
  const videoRef = useRef();
  const [pauseButtonText, setPauseButtonText] = useState("Pause");
  const [consentVideoMicrophone, setConsentVideoMicrophone] = useState(false);
  const [lockStartButton, setLockStartButton] = useState(true);
  const [showPopup, setShowPopup] = useState(true);
  const [countdown, setCountdown] = useState(3);
  const [showCountdown, setShowCountdown] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [latestImage, setLatestImage] = useState(null);
  const [language, setLanguage] = useState("en");
  const [userDescription, setUserDescription] = useState("");
  const [isComplimenting, setIsComplimenting] = useState(false);
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  const [KinderStoriesSpeech, setKinderStoriesSpeech] = useState({});

  useEffect(() => {
    if (!loaded) {
      setTimeout(() => {
        setLockStartButton(false);
      }, 3000);
    }
    if (consentVideoMicrophone) {
      startVideo();
      loadAvatar();
      prepSpeech();
      setShowPopup(false);
    }
    isMobile();
    setConsentVideoMicrophone(false);
  }, [consentVideoMicrophone]);

  // Starts the videofeed.
  const startVideo = () => {
    navigator.mediaDevices
      .getUserMedia({ video: {} })
      .then((stream) => {
        videoRef.current.srcObject = stream;
        videoRef.muted = true;
      })
      .catch((err) => console.error("Error accessing webcam: ", err));
  };

  // Loads the avatar on the screen.
  const loadAvatar = async function loadAvatar() {
    loaded = true;
    // Instantiate the class
    // NOTE: Never put your API key in a client-side code unless you know
    //       that you are the only one to have access to that code!
    const nodeAvatar = document.getElementById("avatar");
    head = new TalkingHead(nodeAvatar, {
      ttsEndpoint:
        "https://eu-texttospeech.googleapis.com/v1beta1/text:synthesize",
      ttsApikey: process.env.REACT_APP_GOOGLE_TTS_API_KEY,
      lipsyncModules: ["en"],
      cameraView: "upper",
    });

    // Load and show the avatar
    const nodeLoading = document.getElementById("loading");
    try {
      nodeLoading.textContent = "Loading...";
      let config = {};
      if (language === "en") {
        config = {
          url: "https://models.readyplayer.me/6704de70b56d1f713429a3fd.glb?morphTargets=ARKit,Oculus+Visemes,mouthOpen,mouthSmile,eyesClosed,eyesLookUp,eyesLookDown&textureSizeLimit=1024&textureFormat=png",
          body: "M",
          ttsVoice: "en-GB-Standard-B",
          avatarMood: "neutral",
          ttsLang: "en-GB",
          lipsyncLang: "en",
        };
      } else if (language === "nl") {
        config = {
          url: "https://models.readyplayer.me/6704de70b56d1f713429a3fd.glb?morphTargets=ARKit,Oculus+Visemes,mouthOpen,mouthSmile,eyesClosed,eyesLookUp,eyesLookDown&textureSizeLimit=1024&textureFormat=png",
          body: "M",
          ttsVoice: "nl-NL-Standard-C",
          avatarMood: "neutral",
          ttsLang: "nl-NL",
          lipsyncLang: "nl",
        };
      }
      await head.showAvatar(config, (ev) => {
        if (ev.lengthComputable) {
          let val = Math.min(1000, Math.round((ev.loaded / ev.total) * 100));
          nodeLoading.textContent = "Loading " + val + "%";
        }
      });
      nodeLoading.style.display = "none";
    } catch (error) {
      nodeLoading.textContent = error.toString();
    }
  };

  // Describes the image of the user everytime the recording is started. This way a description is ready for the conversation.
  const DescribeImage = async () => {
    if (isWaitingForDescriptionResult) {
      return;
    }

    isWaitingForDescriptionResult = true;
    console.log("Describing the image");
    const video = videoRef.current;
    const canvas = document.createElement("canvas");
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
    const image = canvas.toDataURL("image/jpg");
    setLatestImage(image);

    let messages = [];
    if (language === "en") {
      messages = [
        {
          role: "system",
          content: `Describe the person in the image. Do not describe anything about their surroundings. FORMAT: Output as JSON in the following format: {response:'text'} . It is an object with a single string field called "response"`,
        },
        {
          role: "user",
          content: [{ type: "image_url", image_url: { url: image } }],
        },
      ];
    } else if (language === "nl") {
      messages = [
        {
          role: "system",
          content: `Beschrijf de persoon in de afbeelding. Beschrijf niks anders buiten de persoon. Beschrijf de omgeving niet. FORMAT: Output as JSON in the following format: {response:'text'} . It is an object with a single string field called "response". Write in Dutch, maak de beschrijving in het Nederlands`,
        },
        {
          role: "user",
          content: [{ type: "image_url", image_url: { url: image } }],
        },
      ];
    }

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/VHResponse`,
      { messages: messages }
    );
    console.log("Description response: ", response);
    await roastUser(response.data.response);
  };

  // Make a response based on the image of the user and the transcription of the user
  async function roastUser(description) {
    console.log("Getting response to answer the user");
    try {
      let messages = [];

      if (language === "en") {
        messages = [
          {
            role: "system",
            content:
              "Your task is to write a children's story where the user is the main character. Write the character based on the description of the user and the description of the image. Write in English.",
          },
          {
            role: "user",
            content: `The user's name is: ${userDescription}. And is described as follows: ${description}.`,
          },
        ];
      } else if (language === "nl") {
        messages = [
          {
            role: "system",
            content:
              "Jouw taak is om een kinder verhaaltje te schrijven waarin de gebruiker de hoofdpersoon is. Schrijf de hoofdpersoon op basis van de beschrijving van de gebruiker en de beschrijving van de afbeelding. Schrijf in het Nederlands, write in Dutch.",
          },
          {
            role: "user",
            content: `De gebruiker is: ${userDescription}. En wordt als volgt beschreven: ${description}.`,
          },
        ];
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/VHResponseGroq`,
        { messages: messages }
      );
      console.log("Story response: ", response);

      console.log("Getting speech response");
      // Generate audio and visemes
      const speechResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/VHSpeech`,
        { text: response.data.response, voiceId: "bIHbv24MWmeRgasZH58o" }
      ); //{text: response.data.response});

      // Convert Blob to ArrayBuffer
      // const audioBlob = speechResponse.data.response.audio;
      // const arrayBuffer = await audioBlob.arrayBuffer();
      const arrayBuffer = base64ToArrayBuffer(
        speechResponse.data.response.audio
      );

      // Create an AudioContext instance and decode the ArrayBuffer into an AudioBuffer
      const audioContext = new (window.AudioContext ||
        window.webkitAudioContext)();
      const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

      // Create the audio object expected by playAudio
      const audioObj = {
        audio: audioBuffer,
        words: speechResponse.data.response.alignment.words,
        wtimes: speechResponse.data.response.alignment.wtimes,
        wdurations: speechResponse.data.response.alignment.wdurations,
      };

      console.log("Playing audio");
      head.speakAudio(audioObj);

      isAvatarThinking = false;
      isWaitingForDescriptionResult = false;
      isRoasting();
    } catch (error) {
      console.error(error);
    }
  }

  function pauseButton() {
    if (isAvatarThinking) {
      head.start();
      setPauseButtonText("Pause");
    } else {
      head.stop();
      setPauseButtonText("Resume");
    }
    isAvatarThinking = !isAvatarThinking;
  }

  // Close the introduction modal
  function closeModal() {
    setConsentVideoMicrophone(true);
  }

  async function takePicture() {
    head.speakAudio(KinderStoriesSpeech.greeting);

    setShowCountdown(true);
    setTimeout(() => {
      setCountdown(2);
    }, 1000);
    setTimeout(() => {
      setCountdown(1);
    }, 2000);
    setTimeout(() => {
      setShowCountdown(false);
      setCountdown(3);
    }, 3000);
    setTimeout(() => {
      setShowImage(true);
      DescribeImage();
    }, 3000);
  }

  async function isRoasting() {
    if (!head.isSpeaking && pauseButtonText === "Pause") {
      head.speakAudio(KinderStoriesSpeech.ending);
      setTimeout(() => {
        setShowImage(false);
      }, 3000);
    } else {
      setTimeout(() => {
        isRoasting();
      }, 1500);
    }
  }

  async function prepSpeech() {
    const audioContext = new (window.AudioContext ||
      window.webkitAudioContext)();
    if (language === "en") {
      const arrayBufferGreeting = base64ToArrayBuffer(
        KinderStoriesGreetingEN.audio
      );
      KinderStoriesGreetingEN.audio = await audioContext.decodeAudioData(
        arrayBufferGreeting
      );
      const arrayBufferEnding = base64ToArrayBuffer(
        KinderStoriesEndingEN.audio
      );
      KinderStoriesEndingEN.audio = await audioContext.decodeAudioData(
        arrayBufferEnding
      );
      KinderStoriesSpeech.greeting = KinderStoriesGreetingEN;
      KinderStoriesSpeech.ending = KinderStoriesEndingEN;
    } else if (language === "nl") {
      const arrayBufferGreeting = base64ToArrayBuffer(
        KinderStoriesGreetingNL.audio
      );
      KinderStoriesGreetingNL.audio = await audioContext.decodeAudioData(
        arrayBufferGreeting
      );
      const arrayBufferEnding = base64ToArrayBuffer(
        KinderStoriesEndingNL.audio
      );
      KinderStoriesEndingNL.audio = await audioContext.decodeAudioData(
        arrayBufferEnding
      );
      KinderStoriesSpeech.greeting = KinderStoriesGreetingNL;
      KinderStoriesSpeech.ending = KinderStoriesEndingNL;
    }
  }

  function selectLanguage(lang) {
    setLanguage(lang);
  }

  // false -> mean, true -> nice
  function selectAttitude(attitude) {
    setIsComplimenting(attitude);
  }

  const handleInputChange = (event) => {
    setUserDescription(event.target.value);
  };

  // Check whether the user is on a mobile device
  function isMobile() {
    // if (/Mobi|Android/i.test(navigator.userAgent) || window.innerWidth < 786)
    if (window.innerWidth <= 1024) {
      setIsMobileDevice(true);
    }
  }

  return (
    <div>
      <div className="App">
        <div className="KinderStories-avatar-background">
          {showImage && !isMobileDevice ? (
            <img src={latestImage} className="KinderStories-picture" />
          ) : null}
          {showImage && isMobileDevice ? (
            <img src={latestImage} className="KinderStories-picture-mobile" />
          ) : null}
          {showCountdown && isMobileDevice ? (
            <p className="KinderStories-countdown-mobile">{countdown}</p>
          ) : null}
          {showCountdown && !isMobileDevice ? (
            <p className="KinderStories-countdown">{countdown}</p>
          ) : null}

          {isMobileDevice ? (
            <div className="avatar KinderStories-mobile" id="avatar"></div>
          ) : (
            <div className="avatar KinderStories" id="avatar"></div>
          )}
          {isMobileDevice ? (
            <video
              className="KinderStories-videoCam-mobile"
              ref={videoRef}
              playsInline
              autoPlay
              muted
            />
          ) : (
            <video
              className="KinderStories-videoCam"
              ref={videoRef}
              autoPlay
              muted
            />
          )}
          <div id="loading"></div>
        </div>
        {!showPopup && !showImage ? (
          <div className="KinderStories-button-container">
            <button
              className="KinderStories-picture-button KinderStories-button"
              onClick={takePicture}
            >
              Take picture
            </button>
            {/* <button className="KinderStories-pause-button KinderStories-button" onClick={pauseButton}>{pauseButtonText}</button> */}
          </div>
        ) : null}

        {showPopup && !isMobileDevice && (
          <div className="KinderStories-intro-modal">
            <div className="KinderStories-intro-modal__text">
              <div>
                <h3>
                  It's time for Max's{" "}
                  <span style={{ color: "#F36F43" }}>Stories</span>
                </h3>
                <p className="KinderStories-main-text">Pick a language:</p>
                {language === "en" ? (
                  <button className="KinderStories-selected-button">
                    English
                  </button>
                ) : (
                  <button
                    className="KinderStories-button"
                    onClick={() => selectLanguage("en")}
                  >
                    English
                  </button>
                )}
                {language === "nl" ? (
                  <button className="KinderStories-selected-button KinderStories-nederlands">
                    Nederlands
                  </button>
                ) : (
                  <button
                    className="KinderStories-button KinderStories-nederlands"
                    onClick={() => selectLanguage("nl")}
                  >
                    Nederlands
                  </button>
                )}
                <p className="KinderStories-main-text">
                  What's your name?
                </p>
                <input
                  type="text"
                  value={userDescription}
                  onChange={handleInputChange}
                  className="KinderStories-input-field"
                  placeholder="Enter your description..."
                />
              </div>
              <button className="KinderStories-button" onClick={closeModal}>
                Start
              </button>
            </div>
            <div className="KinderStories-intro-modal__image">
              <img src="images/mach8-tile.png" />
            </div>
          </div>
        )}
        {showPopup && isMobileDevice && (
          <div className="KinderStories-intro-modal">
            <div className="KinderStories-intro-modal__text-mobile">
              <div>
                <h3>
                  It's time for Max{" "}
                  <span style={{ color: "#F36F43" }}>Heat</span>
                </h3>
                <p className="KinderStories-main-text">Pick a language:</p>
                {language === "en" ? (
                  <button className="KinderStories-selected-button">
                    English
                  </button>
                ) : (
                  <button
                    className="KinderStories-button"
                    onClick={() => selectLanguage("en")}
                  >
                    English
                  </button>
                )}
                {language === "nl" ? (
                  <button className="KinderStories-selected-button KinderStories-nederlands-mobile">
                    Nederlands
                  </button>
                ) : (
                  <button
                    className="KinderStories-button KinderStories-nederlands-mobile"
                    onClick={() => selectLanguage("nl")}
                  >
                    Nederlands
                  </button>
                )}
                <p className="KinderStories-main-text">Pick Max's attitude:</p>
                {!isComplimenting ? (
                  <button className="KinderStories-selected-button">
                    Roasting
                  </button>
                ) : (
                  <button
                    className="KinderStories-button"
                    onClick={() => selectAttitude(false)}
                  >
                    Roasting
                  </button>
                )}
                {isComplimenting ? (
                  <button className="KinderStories-selected-button KinderStories-complimenting KinderStories-nederlands-mobile">
                    Complimenting
                  </button>
                ) : (
                  <button
                    className="KinderStories-button KinderStories-complimenting-mobile"
                    onClick={() => selectAttitude(true)}
                  >
                    Complimenting
                  </button>
                )}

                <p className="KinderStories-main-text">
                  Add additional context:
                </p>
                <input
                  type="text"
                  value={userDescription}
                  onChange={handleInputChange}
                  className="KinderStories-input-field-mobile"
                  placeholder="Enter context..."
                />
                {/* <p className="final-step">Ask <span style={{color: '#F36F43'}}>Max</span> who he is and what his role is at United Playgrounds</p> */}
              </div>
              <button
                className="KinderStories-button"
                style={{ marginTop: "25px" }}
                onClick={closeModal}
              >
                Start
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
